import { FC } from "react";
import { lazily } from "react-lazily";

import { IconName } from "@components/Icon";
import { Props } from "@components/NavigatorMenu";
import { ApplicationContextValues } from "./context/ApplicationContext.enum";

const { AssetsManagementPage } = lazily(
  () => import("@pages/AssetsManagementPage")
);
const { AuctionsListPage } = lazily(() => import("@pages/AuctionsListPage"));
const { AuctionDetailsPage } = lazily(
  () => import("@pages/AuctionDetailsPage")
);
const { HomePage } = lazily(() => import("@pages/HomePage"));
const { CreateAuctionPage } = lazily(() => import("@pages/CreateAuctionPage"));
const { RequestDisposalPage } = lazily(
  () => import("@pages/RequestDisposalPage")
);
const { WhyToDisposePage } = lazily(() => import("@pages/WhyToDisposePage"));
const { ManageRequestsPage } = lazily(
  () => import("@pages/ManageRequestsPage")
);
const { ManageBidders } = lazily(
  () => import("@pages/ManageBiddersPage/ManageBidders")
);
const { BidderDetailsPage } = lazily(() => import("@pages/ManageBiddersPage"));
const { PrivacyPolicy } = lazily(() => import("@pages/PrivacyPolicy"));
const { TermsAndConditions } = lazily(
  () => import("@pages/TermsAndConditions")
);
const { Blank } = lazily(() => import("@utils/Blank"));

export enum Routes {
  Home = "/",
  WhyToDispose = "/why-to-dispose",
  RequestDisposal = "/request-disposal",
  AuctionsList = "/auctions-list",
  AuctionDetails = "/auction-details/:id",
  AuctionAssetsAndLots = "/auction-assets-lots/:id",
  AuctionReport = "/auction-report/:id",
  CreateAuction = "/create-auction",
  ManageRequests = "/manage-requests",
  AssetsManagement = "/assets-management",
  ManageBidders = "/manage-bidders",
  BidderDetailsPage = "/bidder-details/:id",
  PrivacyPolicy = "/privacy-policy",
  TermsAndConditions = "/terms-and-conditions",
}

type Route = {
  to: Routes;
  icon?: IconName;
  i18nLabelKey?: string;
  component: FC;
};
type RoutesFromContext = { [key in ApplicationContextValues]: Route[] };

export function getLinksAndRoutes(context: ApplicationContextValues) {
  return {
    links: links(context),
    routes: [
      {
        to: Routes.Home,
        icon: "Lock",
        i18nLabelKey: "menu.home",
        component: HomePage,
      },
      {
        to: Routes.WhyToDispose,
        icon: "Handshake",
        i18nLabelKey: "menu.whyToDispose",
        component: WhyToDisposePage,
      },
      {
        to: Routes.RequestDisposal,
        icon: "Hammer",
        i18nLabelKey: "menu.requestDisposal",
        component: RequestDisposalPage,
      },
      {
        to: Routes.AuctionsList,
        icon: "Clipboard",
        i18nLabelKey: "menu.auctionsList",
        component: AuctionsListPage,
      },
      {
        to: Routes.AuctionDetails,
        component: AuctionDetailsPage,
      },
      {
        to: Routes.AuctionAssetsAndLots,
        component: AuctionDetailsPage,
      },
      {
        to: Routes.AuctionReport,
        component: AuctionDetailsPage,
      },
      {
        to: Routes.CreateAuction,
        icon: "Hammer",
        i18nLabelKey: "menu.createAuction",
        component: CreateAuctionPage,
      },
      {
        to: Routes.ManageRequests,
        icon: "File",
        i18nLabelKey: "menu.manageRequests",
        component: ManageRequestsPage,
      },
      {
        to: Routes.AssetsManagement,
        icon: "Folder",
        i18nLabelKey: "menu.assetsManagement",
        component: AssetsManagementPage,
      },
      {
        to: Routes.PrivacyPolicy,
        component: PrivacyPolicy,
      },
      {
        to: Routes.TermsAndConditions,
        component: TermsAndConditions,
      },
      {
        to: Routes.ManageBidders,
        icon: "User",
        i18nLabelKey: "menu.manageBidders",
        component: ManageBidders,
      },
      {
        to: Routes.BidderDetailsPage,
        component: BidderDetailsPage,
      },
    ],
  };
}

export const CONTEXT_LINK: { [key in Routes]: ApplicationContextValues[] } = {
  [Routes.Home]: [
    ApplicationContextValues.Public,
    ApplicationContextValues.DigitalOffice,
  ],
  [Routes.WhyToDispose]: [ApplicationContextValues.Public],
  [Routes.RequestDisposal]: [ApplicationContextValues.Public],
  [Routes.AuctionsList]: [ApplicationContextValues.DigitalOffice],
  [Routes.AuctionDetails]: [ApplicationContextValues.DigitalOffice],
  [Routes.AuctionAssetsAndLots]: [ApplicationContextValues.DigitalOffice],
  [Routes.AuctionReport]: [ApplicationContextValues.DigitalOffice],
  [Routes.CreateAuction]: [ApplicationContextValues.DigitalOffice],
  [Routes.ManageRequests]: [ApplicationContextValues.DigitalOffice],
  [Routes.AssetsManagement]: [ApplicationContextValues.DigitalOffice],
  [Routes.PrivacyPolicy]: [ApplicationContextValues.DigitalOffice],
  [Routes.TermsAndConditions]: [ApplicationContextValues.DigitalOffice],
  [Routes.ManageBidders]: [ApplicationContextValues.DigitalOffice],
  [Routes.BidderDetailsPage]: [ApplicationContextValues.DigitalOffice],
};

const ROUTES_FROM_CONTEXT: RoutesFromContext = {
  [ApplicationContextValues.Public]: [
    {
      to: Routes.Home,
      icon: "Home",
      i18nLabelKey: "menu.home",
      component: HomePage,
    },
    {
      to: Routes.WhyToDispose,
      icon: "Handshake",
      i18nLabelKey: "menu.whyToDispose",
      component: WhyToDisposePage,
    },
    {
      to: Routes.RequestDisposal,
      icon: "Hammer",
      i18nLabelKey: "menu.requestDisposal",
      component: RequestDisposalPage,
    },
  ],
  [ApplicationContextValues.DigitalOffice]: [
    {
      to: Routes.Home,
      icon: "Lock",
      i18nLabelKey: "menu.digitalOfficeHome",
      component: HomePage,
    },
    {
      to: Routes.CreateAuction,
      icon: "Hammer",
      i18nLabelKey: "menu.createAuction",
      component: Blank,
    },
    {
      to: Routes.ManageRequests,
      icon: "File",
      i18nLabelKey: "menu.manageRequests",
      component: ManageRequestsPage,
    },
    {
      to: Routes.AuctionsList,
      icon: "ClipboardCheck",
      i18nLabelKey: "menu.auctionsList",
      component: AuctionsListPage,
    },
    {
      to: Routes.AuctionDetails,
      component: AuctionDetailsPage,
    },
    {
      to: Routes.AuctionAssetsAndLots,
      component: AuctionDetailsPage,
    },
    {
      to: Routes.AuctionReport,
      component: AuctionDetailsPage,
    },
    {
      to: Routes.AssetsManagement,
      icon: "Folder",
      i18nLabelKey: "menu.assetsManagement",
      component: AssetsManagementPage,
    },
    {
      to: Routes.PrivacyPolicy,
      component: PrivacyPolicy,
    },
    {
      to: Routes.TermsAndConditions,
      component: TermsAndConditions,
    },
  ],
};

function links(context: ApplicationContextValues) {
  return ROUTES_FROM_CONTEXT[context]
    .filter((item) => Boolean(item.i18nLabelKey) && Boolean(item.icon))
    .map(({ component, ...link }) => link) as Props["links"];
}
