import Axios from "axios";

import {
  IBidder,
  IBidderDetails,
  ICity,
  ICountry,
  Nullable,
  UserByEmail,
} from "@utils";

export async function citiesByCountry(countryId: number) {
  const response = await Axios.get<ICity[]>(
    `/api/countries/${countryId}/cities/`
  );
  return response.data;
}

export async function countries() {
  const response = await Axios.get<ICountry[]>("/api/countries/");
  return response.data;
}

export async function byEmail(email: string) {
  const response = await Axios.get<UserByEmail>(`/api/users/${email}/`);
  return response.data;
}

export async function blockUser(email: string, message: string) {
  await Axios.patch(`/api/users/blocks/${email}/`, {
    ...(message && { block_reason: message }),
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log("Block user error", e);
      return Promise.reject(e);
    });
}

export async function unblockUser(email: string) {
  const response = await Axios.delete(`/api/users/blocks/${email}/`);
  return response.data;
}

export async function getBidders(page: number, search?: string) {
  const response = await Axios.get<IBidder>("/api/users/bidders/", {
    params: {
      page,
      search: search ? search : "",
    },
  });
  return response.data;
}

export async function getBidderById(
  id: number
): Promise<Nullable<IBidderDetails>> {
  const response = await Axios.get<IBidderDetails>(
    `/api/users/${id}/bidder/analytics/`
  );
  return response.data;
}
