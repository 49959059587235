import {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useState,
  WheelEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FieldArray, useFormikContext } from "formik";
import styled from "styled-components";

import { Button, ButtonTheme } from "@components/Button";
import { FormError } from "@components/FormError";
import { Icon } from "@components/Icon";
import { Input } from "@components/Input";
import { Label } from "@components/Label";
import { MultiSelect } from "@components/MultiSelect";
import { AssetFamily, ASSETS_FAMILY, Nullable, OptionalNotNull } from "@utils";
import { MAX_FEE_VALUE, MIN_FEE_VALUE } from "./consts";
import { TCreateAuctionFormValues } from "./CreateAuctionForm";

const Container = styled.div`
  width: 100%;
`;

const Error = styled.div`
  margin-bottom: ${({ theme }) => theme.tripleMargin};
`;

const Title = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 12rem 12rem;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.tripleMargin};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: minmax(10rem, 1fr) 1fr 10rem 10rem;
  gap: 20px;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.tripleMargin};
`;

const LabelBox = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;

  & ${Label} {
    margin-right: auto;
  }
`;

const TooltipContent = styled.div`
  max-width: 20rem;
`;

const EMPTY_STATE = {
  assets: [] as AssetFamily[],
  fee: undefined as OptionalNotNull<number>,
};

interface Props {
  disabled?: boolean;
}

export const AuctioneerFeeByAssetField: FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();
  const {
    values: { auctioneerFees },
    setFieldValue,
  } = useFormikContext<TCreateAuctionFormValues>();

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<Nullable<typeof EMPTY_STATE>>(null);

  const assetsFamilyListAtIndex = useCallback(
    (index: number) =>
      ASSETS_FAMILY.filter(
        (asset: AssetFamily) =>
          !auctioneerFees.find(
            (fee, idx) => fee.assets.includes(asset) && index !== idx
          )
      ),
    [auctioneerFees]
  );

  const canAddFee = useMemo(
    () =>
      ASSETS_FAMILY.filter(
        (asset: AssetFamily) =>
          !disabled &&
          !value &&
          !auctioneerFees.find((fee) => fee.assets.includes(asset))
      ).length > 0,
    [auctioneerFees, value, disabled]
  );

  const canSaveFee = useMemo(
    () =>
      value?.assets.length &&
      typeof value?.fee === "number" &&
      value.fee >= MIN_FEE_VALUE &&
      value.fee <= MAX_FEE_VALUE,
    [value]
  );

  const handleAssetsChange = useCallback((id: string) => {
    const asset = id as AssetFamily;

    setValue((currentValue) => {
      if (!currentValue) return { assets: [asset], fee: undefined };

      let updatedAssets = [...currentValue.assets];

      if (updatedAssets.includes(asset)) {
        updatedAssets = updatedAssets.filter((a) => a !== asset);
      } else {
        updatedAssets.push(asset);
      }

      return { ...currentValue, assets: updatedAssets };
    });
  }, []);

  const handleFeeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const fee = Number(event.currentTarget.value);

      setValue((currentValue) => {
        if (!currentValue) return { fee, assets: [] };
        return { ...currentValue, fee };
      });
    },
    []
  );

  const handleSaveFee = useCallback(
    (index: number) => {
      const currentFees = auctioneerFees;
      currentFees[index] = value!;

      setFieldValue("auctioneerFees", currentFees);
      setIsEditing(false);
      setValue(null);
    },
    [value, auctioneerFees, setFieldValue]
  );

  const shouldShowSaveButton = useCallback(
    (index: number) => index === auctioneerFees.length - 1 && isEditing,
    [isEditing, auctioneerFees]
  );

  const canRemoveFee = useCallback(
    (index: number) => !isEditing || shouldShowSaveButton(index),
    [isEditing, shouldShowSaveButton]
  );

  return (
    <>
      <FieldArray
        name="auctioneerFees"
        render={(helpers) => (
          <>
            <Title>
              <LabelBox>
                <Label className="mr-6">
                  {t("auctionForm.auctioneerFee")} &#42;
                </Label>
                <div id="auctioneer-fee-tooltip">
                  <Icon.InfoCircle />
                </div>
              </LabelBox>
              <Button
                type="button"
                theme={ButtonTheme.Blue}
                disabled={!canAddFee || isEditing}
                onClick={() => {
                  setIsEditing(true);
                  helpers.push(EMPTY_STATE);
                }}
              >
                {t<string>("addFee")}
              </Button>
            </Title>
            {auctioneerFees && (
              <>
                {auctioneerFees.length > 0 && (
                  <Container>
                    {auctioneerFees.map((_, index) => (
                      <Row key={index}>
                        <MultiSelect
                          selectAll
                          data-testid={`SelectAuctioneerFeeAssetFamily.${index}`}
                          disabled={!shouldShowSaveButton(index)}
                          noSelectedTitle={t("selectAssetFamily")}
                          options={assetsFamilyListAtIndex(index).map((a) => ({
                            id: a,
                            title: a,
                          }))}
                          selectedIds={
                            shouldShowSaveButton(index)
                              ? value?.assets || []
                              : auctioneerFees[index].assets
                          }
                          toggleOption={handleAssetsChange}
                        />
                        <Input
                          data-testid={`AuctioneerFeeValue.${index}`}
                          type="number"
                          value={auctioneerFees[index].fee}
                          placeholder={t("auctionForm.fee")}
                          disabled={!shouldShowSaveButton(index)}
                          onChange={handleFeeChange}
                          onWheel={(e: WheelEvent<HTMLInputElement>) =>
                            e.currentTarget.blur()
                          }
                          min={MIN_FEE_VALUE}
                          max={MAX_FEE_VALUE}
                        />
                        <Button
                          type="button"
                          theme={ButtonTheme.Quaternary}
                          disabled={!canRemoveFee(index)}
                          onClick={() => {
                            setIsEditing(false);
                            setValue(null);
                            helpers.remove(index);
                          }}
                        >
                          {t<string>("removeFee")}
                        </Button>
                        <div>
                          {shouldShowSaveButton(index) && (
                            <Button
                              type="button"
                              theme={ButtonTheme.Primary}
                              disabled={!canSaveFee}
                              onClick={() => handleSaveFee(index)}
                            >
                              {t<string>("saveFee")}
                            </Button>
                          )}
                        </div>
                      </Row>
                    ))}
                  </Container>
                )}
              </>
            )}
          </>
        )}
      />
      <Error>
        <FormError name="auctioneerFees" />
      </Error>
      <Tooltip anchorId="auctioneer-fee-tooltip">
        <TooltipContent>{t("auctionForm.auctioneerFeeTooltip")}</TooltipContent>
      </Tooltip>
    </>
  );
};
