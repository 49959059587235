import { Agency, AssetFamily, Nullable } from ".";

export enum AuctionStatus {
  NEW = 10,
  ASSETS_ADDED = 20,
  LOTS_CREATED = 30,
  UNDER_CD_REVIEW = 40,
  FEEDBACK_BY_CD = 50,
  APPROVED_BY_CD = 60,
  PROMOTION = 65,
  LIVE = 70,
  DONE = 80,
  FINALIZED = 90,
}

interface PersonalData {
  full_name: string;
  email: string;
  phone: string;
}

export interface IAuction {
  id: number;
  status: AuctionStatus;
  auction_date: string;
  auction_end_date: string;
  timezone: string;
  auction_image: Nullable<string>;
  conversion_rate: Nullable<string>;
  requires_online_deposits: boolean;
  bid_file: Nullable<{ obj_name: string }>;
  bid_template: Nullable<{ obj_name: string }>;
  unhcr_lot_count: Nullable<number>;
  unhcr_services: Nullable<number>;
  auctioneer_invoice: Nullable<number>;
  location: {
    geoname_id: number;
    name: string;
    subregion: {
      name: string;
    };
    region: {
      name: string;
    };
    country: {
      geoname_id: number;
      name: string;
    };
  };
  agency: Agency;
  auctioneer_fees?: Nullable<Record<AssetFamily, number>>;
  co_focal_point: Nullable<PersonalData>;
  creation_time: string;
  currency: { code: string };
  deposits?: Nullable<Record<AssetFamily, number>>;
  gas_team_representative: Nullable<PersonalData>;
  payment_account: Nullable<string>;
  payment_conditions: Nullable<string>;
  sealed: boolean;
  unhcr_supervisor: Nullable<PersonalData>;
  lot_count: number;
  is_online: boolean;
  send_email_bid_was_outbid: boolean;
  send_email_bidder_won: boolean;
  email_language: string;
}
