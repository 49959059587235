import { ChangeEvent, useCallback, useMemo, useState, WheelEvent } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FieldArray, useFormikContext } from "formik";
import styled from "styled-components";

import { Button, ButtonTheme } from "@components/Button";
import { FormError } from "@components/FormError";
import { Icon } from "@components/Icon";
import { Input } from "@components/Input";
import { Label } from "@components/Label";
import { MultiSelect } from "@components/MultiSelect";
import { AssetFamily, ASSETS_FAMILY, Nullable, OptionalNotNull } from "@utils";
import { MAX_DEPOSIT_VALUE, MIN_DEPOSIT_VALUE } from "./consts";
import { TCreateAuctionFormValues } from "./CreateAuctionForm";

const Container = styled.div`
  width: 100%;
`;

const Error = styled.div`
  margin-bottom: ${({ theme }) => theme.tripleMargin};
`;

const Title = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 12rem 12rem;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.tripleMargin};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: minmax(10rem, 1fr) 1fr 12rem 10rem;
  gap: 20px;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.tripleMargin};
`;

const LabelBox = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;

  & ${Label} {
    margin-right: auto;
  }
`;

const TooltipContent = styled.div`
  max-width: 20rem;
`;

const EMPTY_STATE = {
  assets: [] as AssetFamily[],
  deposit: undefined as OptionalNotNull<number>,
};

export const DepositByAssetField = () => {
  const { t } = useTranslation();
  const {
    values: { deposits, currency },
    setFieldValue,
  } = useFormikContext<TCreateAuctionFormValues>();

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<Nullable<typeof EMPTY_STATE>>(null);

  const assetsFamilyListAtIndex = useCallback(
    (index: number) =>
      ASSETS_FAMILY.filter(
        (asset: AssetFamily) =>
          !deposits.find(
            (deposit, idx) => deposit.assets.includes(asset) && index !== idx
          )
      ),
    [deposits]
  );

  const canAddDeposit = useMemo(
    () =>
      ASSETS_FAMILY.filter(
        (asset: AssetFamily) =>
          !value && !deposits.find((deposit) => deposit.assets.includes(asset))
      ).length > 0,
    [deposits, value]
  );

  const canSaveDeposit = useMemo(
    () =>
      value?.assets.length &&
      typeof value?.deposit === "number" &&
      value.deposit >= MIN_DEPOSIT_VALUE &&
      value.deposit <= MAX_DEPOSIT_VALUE,
    [value]
  );

  const handleAssetChange = useCallback((id: string) => {
    const asset = id as AssetFamily;

    setValue((currentValue) => {
      if (!currentValue) return { assets: [asset], deposit: undefined };

      let updatedAssets = [...currentValue.assets];

      if (updatedAssets.includes(asset)) {
        updatedAssets = updatedAssets.filter((a) => a !== asset);
      } else {
        updatedAssets.push(asset);
      }

      return { ...currentValue, assets: updatedAssets };
    });
  }, []);

  const handleDepositChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const deposit = Number(event.currentTarget.value);

      setValue((currentValue) => {
        if (!currentValue) return { deposit, assets: [] };
        return { ...currentValue, deposit };
      });
    },
    []
  );

  const handleSaveDeposit = useCallback(
    (index: number) => {
      const currentDeposits = deposits;
      currentDeposits[index] = value!;

      setFieldValue("deposits", currentDeposits);
      setIsEditing(false);
      setValue(null);
    },
    [value, deposits, setFieldValue]
  );

  const shouldShowSaveButton = useCallback(
    (index: number) => index === deposits.length - 1 && isEditing,
    [isEditing, deposits]
  );

  const canRemoveDeposit = useCallback(
    (index: number) => !isEditing || shouldShowSaveButton(index),
    [isEditing, shouldShowSaveButton]
  );

  return (
    <>
      <FieldArray
        name="deposits"
        render={(helpers) => (
          <>
            <Title>
              <LabelBox>
                <Label className="mr-6">
                  {currency
                    ? `${t("auctionForm.deposit")} (${currency})`
                    : t("auctionForm.deposit")}{" "}
                  &#42;
                </Label>
                <div id="deposit-fee-tooltip">
                  <Icon.InfoCircle />
                </div>
              </LabelBox>
              <Button
                type="button"
                theme={ButtonTheme.Blue}
                disabled={!canAddDeposit || isEditing}
                onClick={() => {
                  setIsEditing(true);
                  helpers.push(EMPTY_STATE);
                }}
              >
                {t<string>("addDeposit")}
              </Button>
            </Title>
            {deposits && (
              <>
                {deposits.length > 0 && (
                  <Container>
                    {deposits.map(({ deposit }, index) => (
                      <Row key={index}>
                        <MultiSelect
                          selectAll
                          data-testid={`SelectDepositAssetFamily.${index}`}
                          disabled={!shouldShowSaveButton(index)}
                          noSelectedTitle={t("selectAssetFamily")}
                          options={assetsFamilyListAtIndex(index).map((a) => ({
                            id: a,
                            title: a,
                          }))}
                          selectedIds={
                            shouldShowSaveButton(index)
                              ? value?.assets || []
                              : deposits[index].assets
                          }
                          toggleOption={handleAssetChange}
                        />
                        <Input
                          data-testid={`DepositValue.${index}`}
                          type="number"
                          value={deposits[index].deposit}
                          placeholder={
                            currency
                              ? `${t("auctionForm.deposit")} (${currency})`
                              : t("auctionForm.deposit")
                          }
                          disabled={!shouldShowSaveButton(index)}
                          onChange={handleDepositChange}
                          onWheel={(e: WheelEvent<HTMLInputElement>) =>
                            e.currentTarget.blur()
                          }
                          min={MIN_DEPOSIT_VALUE}
                          max={MAX_DEPOSIT_VALUE}
                        />
                        <Button
                          type="button"
                          theme={ButtonTheme.Quaternary}
                          disabled={!canRemoveDeposit(index)}
                          onClick={() => {
                            setIsEditing(false);
                            setValue(null);
                            helpers.remove(index);
                          }}
                        >
                          {t<string>("removeDeposit")}
                        </Button>
                        <div>
                          {shouldShowSaveButton(index) && (
                            <Button
                              type="button"
                              theme={ButtonTheme.Primary}
                              disabled={!canSaveDeposit}
                              onClick={() => handleSaveDeposit(index)}
                            >
                              {t<string>("saveDeposit")}
                            </Button>
                          )}
                        </div>
                      </Row>
                    ))}
                  </Container>
                )}
              </>
            )}
          </>
        )}
      />
      <Error>
        <FormError name="deposits" />
      </Error>
      <Tooltip anchorId="deposit-fee-tooltip">
        <TooltipContent>{t("auctionForm.depositTooltip")}</TooltipContent>
      </Tooltip>
    </>
  );
};
