import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  padding-left: 24px;
  max-width: 70%;
`;

export const PrivacyPolicyEs = (): React.ReactElement => {
  return (
    <Container>
      <h2>WFP Online Auctions</h2>
      <h3>Privacy Policy</h3>
      <p>
        This Privacy Policy governs the collection, use, and disclosure of
        personal information by WFP Online Auctions, a Global Shared Service
        powered by the World Food Programme ("WFP") where UN, NGOs,
        governmental, humanitarian, and development parties can participate in
        an auction.
      </p>

      <h3>Introduction</h3>
      <p>
        WFP Online Auctions is committed to protecting the privacy and
        confidentiality of its users' personal information. This policy outlines
        how we collect, use, and safeguard the information provided by users
        when accessing and utilizing the WFP Online Auctions mobile app.
      </p>

      <h3>Collection and Use of Personal Information</h3>
      <p>
        WFP Online Auctions may collect certain personally identifiable
        information (PII) from users to facilitate the bid processing. This
        information is only used for the specific purpose of auction
        participation. The collection of PII is necessary to ensure the
        efficient provision of services and to comply with implicit obligations.
        WFP Online Auctions do not use PII for any other purposes.
      </p>

      <h3>Non-personally Identifiable Information</h3>
      <p>
        By accessing WFP Online Auctions, certain information such as Internet
        protocol (IP) addresses, navigation through WFP Online Auctions, the
        browser used, and the time spent, along with other similar information,
        will be stored on our servers and on a third-party analytics software.
        This information is treated confidentially. The purpose of the
        non-personal data collection is to better understand the preferences of
        the WFP Online Auctions visitors and improve their experience. This
        information is never connected with any personal information that
        visitors supply to us.
      </p>

      <h3>Security Measures</h3>
      <p>
        WFP Online Auctions employs reasonable security measures to protect the
        confidentiality and integrity of users' personal information. These
        measures include encryption, secure data storage, and restricted access
        to personal data.
      </p>

      <h3>Data Deletion Request</h3>
      <p>
        Users can request the deletion of their personal data by contacting
        support.auctiondigitaloffice@wfp.org, explaining the reason for the data
        deletion request. This email will initiate the data deletion process.
        Please note that certain data may be retained for a limited period as
        required by applicable laws or regulations.
      </p>

      <h3>Third-Party Disclosure</h3>
      <p>
        WFP Online Auctions do not disclose personal information to third
        parties, except as necessary to facilitate the requested services and as
        required by law or legal processes.
      </p>

      <h3>Changes to the Privacy Policy</h3>
      <p>
        WFP Online Auctions may update this Privacy Policy from time to time. It
        is advisable to review this policy periodically to stay informed about
        how personal information is being protected.
      </p>

      <h3>Dispute Resolution</h3>
      <p>
        Any dispute, controversy or claim arising out of or relating to this
        Privacy Notice, shall be settled by Arbitration in accordance with the
        UNCITRAL Arbitration Rules then in force. The arbitral tribunal shall
        have no authority to award punitive damages. WFP and the User shall be
        bound by any arbitration award rendered as a result of such arbitration
        as the final adjudication of any such Dispute. The language of any such
        proceedings shall be English.
      </p>

      <h3>Privileges and Immunities</h3>
      <p>
        Nothing in or relating to this privacy policy or this Service shall be
        deemed a waiver of any of the privileges and immunities of the WFP under
        the Convention on the Privileges and Immunities of the United Nations
        and the Convention on the Privileges and Immunities of the Specialized
        Agencies.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns about this privacy policy or the
        handling of your personal information, please contact us at
        support.auctiondigitaloffice@wfp.org.
      </p>
    </Container>
  );
};
