import { FC, HTMLAttributes, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";

import { Checkbox } from "./Checkbox";
import { Icon } from "./Icon";

const appear = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Dropdown = styled.div`
  background-color: ${({ theme }) => theme.darkestBlue};
  border: 1px solid ${({ theme }) => theme.secondaryBlue};
  border-radius: 4px;
  color: ${({ theme }) => theme.bodyColor};
  font-size: ${({ theme }) => theme.smallFontSize};
  font-weight: 400;
  width: 100%;
  position: relative;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Selected = styled.div`
  padding: ${({ theme }) => `${theme.doublePadding} ${theme.oneSixthPadding}`};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    height: 18px;
  }
`;

const Title = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Options = styled.ul`
  display: none;
  position: absolute;
  width: 100%;
  padding: 5px 0px;
  margin: 0;
  animation: ${appear} 0.1s linear;
  z-index: 10;

  ${Dropdown}:hover & {
    display: block;
  }
`;

const Option = styled.li`
  background-color: ${({ theme }) => theme.secondaryBlue};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
  cursor: pointer;
`;

export interface Props extends HTMLAttributes<HTMLDivElement> {
  selectAll?: boolean;
  disabled?: boolean;
  noSelectedTitle: string;
  options: { id: string; title: string }[];
  selectedIds: string[];
  toggleOption: (id: string) => void;
}

export const MultiSelect: FC<Props> = ({
  disabled,
  noSelectedTitle,
  options,
  selectedIds,
  toggleOption,
  selectAll = false,
  ...rest
}) => {
  const selectedTitle = useMemo(() => {
    return options
      .filter((o) => selectedIds.includes(o.id))
      .map((o) => o.title)
      .join(", ");
  }, [options, selectedIds]);

  const [allSelected, setAllSelected] = useState(false);

  const handleSelectAll = () => {
    const newAllSelected = !allSelected;
    if (newAllSelected) {
      options
        .filter((option) => !selectedIds.includes(option.id))
        .forEach((option) => {
          toggleOption(option.id);
        });
    } else {
      options
        .filter((option) => selectedIds.includes(option.id))
        .forEach((option) => {
          toggleOption(option.id);
        });
    }
    setAllSelected(newAllSelected);
  };

  return (
    <Dropdown
      {...rest}
      className={[rest.className]
        .concat([disabled ? "disabled" : ""])
        .join(" ")}
    >
      <Selected>
        <Title>
          {selectedIds.length === 0 ? noSelectedTitle : `${selectedTitle}`}
        </Title>
        <Icon.ChevronDown />
      </Selected>
      {!disabled ? (
        <Options>
          {selectAll && (
            <Option key={"all"}>
              <Checkbox
                id={`checkbox-all`}
                checked={allSelected}
                onChange={handleSelectAll}
              />
              <CheckboxLabel htmlFor={`checkbox-all`}>Select All</CheckboxLabel>
            </Option>
          )}
          {options.map((option) => (
            <Option key={option.id}>
              <Checkbox
                id={`checkbox-${option.id}`}
                checked={selectedIds.includes(option.id)}
                onChange={() => toggleOption(option.id)}
              />
              <CheckboxLabel htmlFor={`checkbox-${option.id}`}>
                {option.title}
              </CheckboxLabel>
            </Option>
          ))}
        </Options>
      ) : null}
    </Dropdown>
  );
};
